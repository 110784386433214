import styled, { css } from 'styled-components';

import { colors } from '~/lib/colors';

export const CloseIconAndFilters = styled.article<{ visible?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 0;

  & > svg {
    cursor: pointer;
    margin: 0 1.5rem 6px;
    margin-left: 0;
  }
`;

export const SearchFilters = styled.article`
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  padding: 1rem;
  padding-left: 0;
  width: 100%;
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SearchInputAndButton = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 1.5rem;
  padding-right: 7px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  &:focus-within {
    border-color: rgb(49, 130, 206);
  }

  & > button {
    padding: 7px;
    height: max-content;
    width: max-content;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 400;

    & > svg {
      fill: white;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

export const SearchInput = styled.input`
  position: relative;
  width: 100%;
  font-family: inherit;
  font-size: 1.5rem;
  line-height: inherit;
  outline: none;
  border: none;
  padding: 8px 0;
  padding-right: 1rem;
  background-color: transparent;
  transition: border-color 0.2s ease-in-out;

  &::placeholder {
    opacity: 0.7;
  }
`;

export const SearchInputContainer = styled.article`
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 1.5rem;
    cursor: pointer;
  }
`;

const sharedFilterStyles = css`
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: transparent dashed 1.5px;
  outline-offset: 2px;
  padding: 5px 8px;
  text-transform: capitalize;
  white-space: nowrap;
  transition-property: outline-color, background-color, color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:focus-visible {
    outline-color: ${colors.black};
  }
`;

const sharedActiveFilterStyles = css`
  background-color: ${colors.primary};
  color: ${colors.white};
  border-color: transparent;
`;

export const SearchFilter = styled.input.attrs({ type: 'checkbox' })`
  ${sharedFilterStyles}
  appearance: none;

  &:checked {
    ${sharedActiveFilterStyles}
  }

  &::before {
    content: attr(data-label);
  }
`;

export const SearchCustomFilter = styled.article.attrs({ tabIndex: 0 })<{
  active?: boolean;
}>`
  ${sharedFilterStyles}
  line-height: 0;
  text-transform: capitalize;
  min-height: 2.9rem;

  & > svg {
    margin-left: 8px;
    height: 1.1rem;
    width: 1.1rem;

    ${({ active }) => active && `fill: ${colors.white};`}
  }

  ${({ active }) => active && sharedActiveFilterStyles}
`;

export const SearchCustomFilterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;

  & > button {
    width: 100%;

    &:not(:first-child) {
      margin-left: 3rem;
    }
  }
`;
