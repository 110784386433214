import styled from 'styled-components';

import { colors } from '~/lib/colors';

import { Btn } from '../button/button.styles';

export const PageNavigatorContainer = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;

export const PageNavigatorPageAndButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p {
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 28.125em) {
    flex-direction: row;

    & > p {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

export const PageNavigatorButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const PageNavigatorButton = styled(Btn).attrs({ transparentBg: true })<{
  previous?: boolean;
}>`
  border-radius: 50%;
  padding: 0;
  height: 3rem;
  width: 3rem;

  & > svg {
    margin: 0;
    height: 1.4rem;
    width: 1.4rem;
    transition: fill 0.2s ease-in-out;

    ${({ previous }) => previous && 'transform: rotate(180deg);'}
  }

  &:hover {
    & > svg {
      fill: ${colors.white};
    }
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
