import { Dispatch, SetStateAction, useEffect } from 'react';

interface IUseRedirectToInitialPageProps {
  page: number;
  dataCount: number;
  isSuccess: boolean;
  setPage: Dispatch<SetStateAction<number>>;
}

const useRedirectToInitialPage = ({
  isSuccess,
  page,
  dataCount,
  setPage,
}: IUseRedirectToInitialPageProps) => {
  useEffect(() => {
    if (isSuccess && page !== 1 && dataCount < 10) {
      setPage(1);
    }
  }, [isSuccess, page, dataCount, setPage]);

  return null;
};

export default useRedirectToInitialPage;
