import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const Form = styled.form<{ rightAlignedButton?: boolean }>`
  width: 100%;

  ${({ rightAlignedButton }) =>
    rightAlignedButton &&
    `& > button {
      margin-left: auto;
  }`}
`;

export const FormGroup = styled.div`
  width: 100%;

  @media only screen and (min-width: 43.75em) {
    display: flex;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

export const FormInputContainer = styled.div<{
  withSvg?: boolean;
  withEyeSvg?: boolean;
  isInputInvalid?: boolean;
}>`
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  transition: margin-bottom 0.2s ease-in-out;

  & > label {
    display: block;
    font-size: 1.4rem;
    color: ${colors.gray3};
    cursor: pointer;
    text-transform: capitalize;
    width: fit-content;
    margin-bottom: 5px;

    & > span {
      color: ${colors.error};
    }
  }

  & > input,
  & > select,
  & > textarea {
    position: relative;
    height: 4.5rem;
    width: 100%;
    font-family: inherit;
    font-size: 1.5rem;
    line-height: inherit;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 1rem 1.6rem;
    background-color: transparent;
    transition: border-color 0.2s ease-in-out;

    &::placeholder {
      opacity: 0.7;
    }

    &:focus {
      border-color: rgb(49, 130, 206);
    }
  }

  & > select {
    appearance: none;
  }

  & > textarea {
    height: 8rem;
    resize: none;
  }

  ${({ withSvg }) =>
    withSvg &&
    `
    & > svg {
      position: absolute;
      height: 1.2rem;
      width: 1.2rem;
      top: 58%;
      right: 1rem;
      pointer-events: none;
    }`}

  ${({ isInputInvalid }) =>
    isInputInvalid &&
    `
    margin-bottom: 3rem;

    & > input {
      border-color: ${colors.error};

      &:focus {
        border-color: ${colors.error};
      }
    }
  `}

  ${({ withEyeSvg }) =>
    withEyeSvg &&
    `& > svg {
      position: absolute;
      cursor: pointer;
      right: 1rem;
      top: 53%;
    }`}
`;
