import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  PageNavigatorButton,
  PageNavigatorButtons,
  PageNavigatorContainer,
  PageNavigatorPageAndButtons,
} from './page-navigator.styles';

import SvgIcon from '../svg-icon/svg-icon.component';

interface IPageNavigatorProps {
  currentPage: number;
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const PageNavigator: FC<IPageNavigatorProps> = ({
  currentPage,
  totalPages,
  setPage,
}) => {
  const router = useRouter();

  const handlePageChange = (pageNo: number) => {
    if (pageNo < 1) return;

    if (pageNo > totalPages) return;

    setPage(pageNo);

    router.replace(
      { pathname: router.pathname, query: { ...router.query, page: pageNo } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  useEffect(() => {
    if (router.isReady && router?.query?.page) {
      setPage(Number(router?.query?.page) || 1);
    }
  }, [router, setPage]);

  return (
    <PageNavigatorContainer>
      <p>
        Showing <strong>10</strong> results per page
      </p>

      <PageNavigatorPageAndButtons>
        <p>
          Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
        </p>

        <PageNavigatorButtons>
          {currentPage > 1 && (
            <PageNavigatorButton
              previous
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <SvgIcon iconName="arrow-right" />
            </PageNavigatorButton>
          )}

          {currentPage < totalPages && (
            <PageNavigatorButton
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <SvgIcon iconName="arrow-right" />
            </PageNavigatorButton>
          )}
        </PageNavigatorButtons>
      </PageNavigatorPageAndButtons>
    </PageNavigatorContainer>
  );
};

export default PageNavigator;
