import { FC, ReactNode } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { SearchCustomFilter } from './search.styles';

import SvgIcon from '../svg-icon/svg-icon.component';

import { colors } from '../../lib/colors';

export interface ICustomFilterProps {
  label: string;
  active: boolean;
  children: ReactNode;
}

const CustomFilter: FC<ICustomFilterProps> = ({ label, active, children }) => {
  return (
    <Popup
      trigger={
        <SearchCustomFilter active={active}>
          {label} <SvgIcon iconName="chevron-down" black />
        </SearchCustomFilter>
      }
      position="bottom right"
      on="click"
      mouseEnterDelay={500}
      mouseLeaveDelay={500}
      arrow={true}
      arrowStyle={{
        marginTop: '3px',
        boxShadow: 'none',
        stroke: 'rgba(0, 0, 0, 0.26)',
      }}
      contentStyle={{
        zIndex: 998,
        width: 'fit-content',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: 0,
        height: 0,
      }}
      closeOnDocumentClick
      keepTooltipInside
    >
      <CustomFilterContainer>{children}</CustomFilterContainer>
    </Popup>
  );
};

const CustomFilterContainer = styled.div`
  background: ${colors.white};
  padding: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  margin-top: 3px;
  border: 1px solid rgba(0, 0, 0, 0.26);
`;

export default CustomFilter;
