import {
  ChangeEvent,
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { Form } from '../form/form.styles';
import {
  CloseIconAndFilters,
  SearchInputContainer,
  SearchFilters,
  SearchFilter,
  SearchInputAndButton,
  SearchInput,
} from './search.styles';

import SvgIcon from '../svg-icon/svg-icon.component';
import ShowView from '../show-view/show-view.component';
import CustomFilter from './custom-filter.component';
import DateFilter from './date-filter.component';
import Button from '../button/button.component';

import { getItem, setItem } from '~/utils/sessionStorage';

interface SearchProps {
  onSearchInputChange: (text: string) => void;
  onFiltersChange: (searchFilters: Record<string, string | undefined>) => void;
  searchPlaceholder?: string;
  filters?: {
    label: string;
    name: string;
    value: string;
    type: 'checkbox' | 'date';
  }[];
  children?: (
    handleFilterChange: (name: string, value: string) => void,
    resetFilters: () => void
  ) => ReactNode;
}

type Search = FC<SearchProps> & {
  Filter: typeof SearchFilter;
  CustomFilter: typeof CustomFilter;
  DateFilter: typeof DateFilter;
};

const Search: Search = ({
  onSearchInputChange,
  onFiltersChange,
  children,
  searchPlaceholder = 'Search here',
  filters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchFilters, setSearchFilters] = useState<
    Record<string, string | undefined>
  >({});
  const [searchInput, setSearchInput] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  const router = useRouter();

  const { pathname } = router;

  const resetFilters = () => {
    setItem(pathname, JSON.stringify({}));

    setSearchFilters({});
  };

  const toggleFilters = () => {
    resetFilters();

    setShowFilters((prev) => !prev);
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchFilters((prev) => ({
      ...prev,
      [name]: !e.target.checked ? undefined : value,
    }));
  };

  useLayoutEffect(() => {
    if (router.isReady && router.query.search && !isInitialized) {
      const searchQuery = router.query.search as string;

      onSearchInputChange(searchQuery);

      setSearchInput(searchQuery);

      setIsInitialized(true);
    }
  }, [router, onSearchInputChange, isInitialized]);

  useEffect(() => {
    const savedSearchFilters = getItem(pathname);

    if (savedSearchFilters) {
      const parsedFilters = JSON.parse(savedSearchFilters);

      if (
        Object.keys(parsedFilters || {}).length &&
        !Object.keys(searchFilters).length
      ) {
        setShowFilters(true);

        setSearchFilters(parsedFilters);
      }
    }
  }, [pathname, searchFilters]);

  useEffect(() => {
    onFiltersChange(searchFilters);

    setItem(pathname, JSON.stringify(searchFilters));
  }, [searchFilters, onFiltersChange, pathname]);

  const handleSearch = (value: string) => {
    onSearchInputChange(value.trim());

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, search: value.trim() },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  return (
    <Form
      style={{ marginTop: '-2rem', marginBottom: '3rem' }}
      onSubmit={(e) => {
        e.preventDefault();

        handleSearch(searchInput);
      }}
    >
      <SearchInputContainer>
        <SearchInputAndButton>
          <SearchInput
            type="search"
            name="search"
            id="search"
            placeholder={searchPlaceholder}
            value={searchInput}
            onChange={(e) => {
              const value = e.target.value;

              setSearchInput(value);

              handleSearch(value);
            }}
          />

          <Button>
            <SvgIcon iconName="search" />
          </Button>
        </SearchInputAndButton>

        <ShowView when={!!filters?.length || !!children}>
          <SvgIcon
            iconName="filters"
            tabIndex={0}
            black
            big
            onClick={toggleFilters}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === 'enter') {
                toggleFilters();
              }
            }}
          />
        </ShowView>
      </SearchInputContainer>

      <ShowView when={showFilters}>
        <CloseIconAndFilters>
          {!!Object.values(searchFilters).find((el) => !!el) && (
            <SvgIcon iconName="close" black onClick={resetFilters} />
          )}

          <SearchFilters>
            {filters?.map((filter, i) => {
              if (filter.type === 'checkbox') {
                return (
                  <Search.Filter
                    key={i}
                    name={filter.name}
                    id={filter.value}
                    value={filter.value}
                    data-label={filter.label}
                    checked={searchFilters[filter.name] === filter.value}
                    onChange={handleFilterChange}
                  />
                );
              }

              if (filter.type === 'date') {
                return (
                  <Search.DateFilter
                    key={i}
                    label={filter.label}
                    name={filter.name}
                    active={!!searchFilters[filter.name]}
                    values={searchFilters[filter.name]}
                    onFilter={(date: string) =>
                      setSearchFilters((prev) => ({
                        ...prev,
                        [filter.name]: date,
                      }))
                    }
                    onClearFilter={() =>
                      setSearchFilters((prev) => ({
                        ...prev,
                        [filter.name]: undefined,
                      }))
                    }
                  />
                );
              }
            })}

            {children?.(
              (name, value) =>
                setSearchFilters((prev) => ({ ...prev, [name]: value })),
              resetFilters
            )}
          </SearchFilters>
        </CloseIconAndFilters>
      </ShowView>
    </Form>
  );
};

Search.Filter = SearchFilter;

Search.CustomFilter = CustomFilter;

Search.DateFilter = DateFilter;

export default Search;
