import { ChangeEvent, FC, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { FormInputContainer } from '../form/form.styles';
import { SearchCustomFilterButtons } from './search.styles';

import CustomFilter, { ICustomFilterProps } from './custom-filter.component';
import Button from '../button/button.component';
import ShowView from '../show-view/show-view.component';

import { DATE_FORMAT } from '~/utils/constants';

type IDateFilterProps = Pick<ICustomFilterProps, 'label' | 'active'> & {
  name: string;
  onFilter: (date: string) => void;
  onClearFilter: () => void;
  values?: string;
};

dayjs.extend(isSameOrBefore);

const getInitialState = (values: IDateFilterProps['values']) => {
  if (!values) return { from: '', to: '' };

  const [from, to] = values.split(',');

  return {
    from:
      dayjs(from).isValid() && dayjs(from).format(DATE_FORMAT) !== '2022-01-01'
        ? dayjs(from).format(DATE_FORMAT)
        : '',
    to:
      dayjs(to).isValid() &&
      dayjs(to).startOf('day').isSameOrBefore(dayjs().startOf('day'))
        ? dayjs(to).format(DATE_FORMAT)
        : '',
  };
};

const DateFilter: FC<IDateFilterProps> = ({
  label,
  active,
  onClearFilter,
  onFilter,
  values,
}) => {
  const [dates, setDates] = useState(getInitialState(values));

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const handleClearDates = () => {
    setDates({ from: '', to: '' });

    onClearFilter();
  };

  const handleFilter = () => {
    if (!dates.from && !dates.to) return;

    const from = dayjs(dates.from || '2022-01-01')
      .startOf('day')
      .toISOString();

    const to = dayjs(dates.to || dayjs(dates.from).add(2, 'year'))
      .endOf('day')
      .toISOString();

    onFilter(`${from},${to}`);
  };

  return (
    <CustomFilter label={label} active={active}>
      <div style={{ width: '25rem' }}>
        <FormInputContainer>
          <label htmlFor="from">From</label>

          <input
            type="date"
            name="from"
            id="from"
            value={dates.from}
            min="2022-02-01"
            onChange={handleDateChange}
          />
        </FormInputContainer>

        <FormInputContainer>
          <label htmlFor="to">To</label>

          <input
            type="date"
            name="to"
            id="to"
            value={dates.to}
            onChange={handleDateChange}
            max={dayjs().endOf('year').format(DATE_FORMAT)}
          />
        </FormInputContainer>

        <SearchCustomFilterButtons>
          <ShowView when={!!dates.from || !!dates.to}>
            <Button br5 danger onClick={handleClearDates}>
              Clear
            </Button>
          </ShowView>

          <Button br5 onClick={handleFilter}>
            Filter
          </Button>
        </SearchCustomFilterButtons>
      </div>
    </CustomFilter>
  );
};

export default DateFilter;
